<template>
  <b-card class="manage-jobs">
    <header-table title="فرص العمل" @create="onClickedAdd" />

    <div class="border pt-1 px-1 rounded-lg shadow-sm">
      <b-row>
        <b-col cols="3">
          <form-input label="" v-model="filter" placeholder="ابحث هنا..." class="w-50" />
        </b-col>
        <b-col cols="3">
          <form-select
            label=""
            v-model="type_id"
            placeholder="إختر نوع الفرصة ..."
            class="w-50"
            :reduce="(x) => x.id"
            :getOptionLabel="(x) => x.name"
            id="opportunity_type_id"
            ep="select/opportunity-types"
            rules="required"
          />
        </b-col>
        <b-col cols="6" class="d-flex justify-content-end">
          <div class="d-flex justify-content-end">
            <b-button
              v-for="(item, i) in filterDate"
              :key="i"
              size="md"
              variant="outline-success"
              pill
              class="mr-50 ml-50 h-75"
              @click="
                utils.status = item.status;
                $refs.refOpportunitiesDropdown.refreshOptions({
                  status: utils.status,
                });
              "
              :class="utils.status == item.status ? 'bg-light-success' : ''"
              >{{ item.label }}</b-button
            >
          </div>
        </b-col>
      </b-row>
    </div>

    <div>
      <dropdown-data
        ref="refOpportunitiesDropdown"
        ep="/opportunities"
        :filter.sync="filter"
        collapseType=""
        id="opportunitiesDropdown"
      >
        <template #header="item">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <h6>{{ item.title }}</h6>
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="onUpdateClicked(item)" v-can="{ slug: $route.meta.slug, action: 'U' }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">تعديل</span>
              </b-dropdown-item>

              <b-dropdown-item @click="onDelete(item)" v-can="{ slug: $route.meta.slug, action: 'D' }">
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">حذف</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
        <template #detals="item">
          <b-row>
            <b-col cols="8">
              <b-card>
                <h5 class="text-primary">التفاصيل</h5>
                <div v-html="item.description"></div>
                <hr />
                <div class="d-flex">
                  <div class="mx-2">
                    <h5 class="text-primary">الجهة</h5>
                    <h6>{{ item.donor_work }}</h6>
                  </div>
                  <div class="mx-2">
                    <h5 class="text-primary">تاريخ الإعلان</h5>
                    <h6>{{ item.publication_date }}</h6>
                  </div>
                  <div class="mx-2">
                    <h5 class="text-primary">تاريخ الإغلاق</h5>
                    <h6>{{ item.end_publication_date }}</h6>
                  </div>
                </div>
              </b-card>
            </b-col>
            <b-col cols="4">
              <b-card>
                <h6 class="text-primary">معلومات التواصل</h6>
                <div v-html="item.contact_info"></div>
              </b-card>
            </b-col>
          </b-row>
        </template>
      </dropdown-data>
    </div>
    <form-modal
      ref="estateModalJobs"
      :formSchema="estatesFormSchema"
      title="إضافة"
      v-model="activModel"
      :loading="loading"
      @confirm="onModalConfirmed"
      size="lg"
    >
    </form-modal>
  </b-card>
</template>
<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BSkeleton,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import DataTable from "@/components/data-table/index";
import FormInput from "@/components/form-input/index";
import Ripple from "vue-ripple-directive";
import FormModal from "@/components/FormModal.vue";
import FormFile from "@/components/form-file/index.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { mapGetters, mapActions } from "vuex";
import Pagination from "@/components/pagination/index.vue";
import FormSelect from "@/components/form-select/index.vue";
import { debounce } from "lodash";
import DropdownData from "@/components/dropdown-data/index.vue";
import HeaderTable from "@/components/data-table/components/header-table.vue";

export default {
  components: {
    HeaderTable,
    BCard,
    DataTable,
    BButton,
    FormInput,
    BRow,
    BCol,
    FormModal,
    FormFile,
    AppCollapse,
    AppCollapseItem,
    Pagination,
    FormSelect,
    BSkeleton,
    DropdownData,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },

  data: () => {
    return {
      utils: {
        status: "",
      },
      filterDate: [
        { id: 1, label: "كل الفرص", status: "" },
        { id: 1, label: "الفرص الحالية", status: "upcoming" },
        { id: 1, label: "الفرص السابقة", status: "previous" },
      ],
      images: [],
      activModel: false,
      currentPage: 1,
      per_page: 10,
      type_id: "",
      filter: "",
      estatesFormSchema: {
        opportunity_type_id: {
          component: "FormSelect",
          key: "opportunity_type_id",
          attrs: {
            reduce: (x) => x.id,
            getOptionLabel: (x) => x.name,
            id: "opportunity_type_id",
            label: "نوع الفرصة",
            ep: "select/opportunity-types",
            rules: "required",
          },
        },
        title: {
          component: "FormInput",
          key: "title",
          attrs: {
            label: "اسم الفرصة",
            rules: "required",
          },
        },

        donor_work: {
          component: "FormInput",
          key: "donor_work",
          attrs: {
            label: "الجهة",
            rules: "required",
          },
        },

        publication_date: {
          component: "FormDatepicker",
          key: "publication_date",
          attrs: {
            id: "publication_date_jobs",
            label: "تاريخ البداية",
            rules: "required",
            placeholder: "YY-MM-DD",
          },
        },
        end_publication_date: {
          component: "FormDatepicker",
          key: "end_publication_date",
          attrs: {
            id: "end_publication_date_jobs",
            label: "تاريخ النهاية",
            rules: "required",
            placeholder: "YY-MM-DD",
          },
        },

        description: {
          component: "FormQuill",
          key: "description",
          attrs: {
            label: "التفاصيل",
            rules: "required",
          },
        },
        contact_info: {
          component: "FormQuill",
          key: "contact_info",
          attrs: {
            label: "معلومات التواصل",
            rules: "required",
          },
        },
      },
    };
  },
  methods: {
    ...mapActions("manageApp/jobs", ["update", "create", "delete"]),
    unFilter() {
      this.filter = "";
      this.type_id = "";
    },
    onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف النشاط", {
          title: "تأكيد حذف النشاط",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.delete({
              id: item.id,
              page: this.currentPage,
              per_page: this.per_page,
              filter: this.filter,
              type_id: this.type_id,
            }).then(() => {
              this.$refs.refOpportunitiesDropdown.refreshOptions({
                type_id: this.type_id,
              });
            });
        });
    },
    onUpdateClicked(item) {
      var toUpdate = {
        id: item.id,
        title: item.title,
        description: item.description,
        opportunity_type_id: item.opportunityType.id,
        contact_info: item.contact_info,
        donor_work: item.donor_work,
        publication_date: item.publication_date,
        end_publication_date: item.end_publication_date,
      };
      this.$refs.estateModalJobs.init(toUpdate);
      this.activModel = true;
    },
    onClickedAdd() {
      this.$refs.estateModalJobs.init({});
      this.activModel = true;
    },

    onModalConfirmed(form) {
      if (form.id) {
        this.update({ id: form.id, fd: form }).then((res) => {
          if (res.status >= 200 && res.status < 300) {
            this.$refs.refOpportunitiesDropdown.refreshOptions({
              type_id: this.type_id,
            });
            this.activModel = false;
          }
        });
      } else
        this.create({ fd: form }).then((res) => {
          if (res.status >= 200 && res.status < 300) {
            this.$refs.refOpportunitiesDropdown.refreshOptions({
              type_id: this.type_id,
            });
            this.activModel = false;
          }
        });
    },
  },
  watch: {
    type_id: debounce(function (value) {
      this.$refs.refOpportunitiesDropdown.refreshOptions({
        type_id: this.type_id,
      });
    }, 700),
  },
  computed: {
    ...mapGetters("manageApp/jobs", ["loading"]),
  },
  created() {},
};
</script>
